import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, createContext, useContext } from 'react';
import JSZip from 'jszip';
import { toast } from 'components/Toast/functions';
import FolderNavigationController from './controllers/FolderNavigationController';
import StorageController from './controllers/StorageController';
import { chunkPromiseHandler } from '../../common/helper';

const SftpContext = createContext({});

export function SftpProvider({ children, attributes }) {
  console.log('SftpProvider attributes:', attributes);
  const [currentPath, setCurrentPath] = useState('/thread');
  const [currentContents, setCurrentContents] = useState({ folders: [], files: [] });
  const [pathHistory, setPathHistory] = useState(['/thread']);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState('');
  const [progress, setProgress] = useState({ loaded: 0, total: 0 });

  const fetchCurrentPathContents = useCallback(async (path) => {
    setLoading(true);
    setLoadingLabel('Loading folders and files...');
    try {
      const result = await StorageController.listS3Files(path);
      setCurrentContents(result);
    } catch (error) {
      toast('error', { message: 'Error while fetching files' }, 3000);
    } finally {
      setLoading(false);
      setLoadingLabel('');
    }
  }, []);

  useEffect(() => {
    fetchCurrentPathContents(currentPath);
  }, [currentPath, fetchCurrentPathContents]);
  useEffect(() => {
    console.log('Updated pathHistory:', pathHistory);
  }, [pathHistory]);
  useEffect(() => {
    console.log('Updated currentPath:', currentPath);
  }, [currentPath]);
  useEffect(() => {
    console.log('Updated searchResults:', searchResults);
  }, [searchResults]);

  const handlePathChange = useCallback((newPath) => {
    setCurrentPath(newPath);
    setPathHistory((prevHistory) => [...prevHistory, newPath]);
    fetchCurrentPathContents(newPath); // Directly call fetchCurrentPathContents
  }, []);

  const handleGoBack = useCallback(() => {
    if (pathHistory.length > 1) {
      const newHistory = [...pathHistory];
      newHistory.pop(); // Remove current path
      const previousPath = newHistory[newHistory.length - 1];
      setCurrentPath(previousPath);
      setPathHistory(newHistory);
    }
  }, [pathHistory]);

  const searchFiles = useCallback(
    async (searchTerm) => {
      setLoading(true);
      setLoadingLabel('Searching files...');
      try {
        const result = await StorageController.searchS3Files(searchTerm);
        if (result) {
          // Navigate to the resulting path
          handlePathChange(result);
          // Clear the search
          setSearch('');
          setSearchResults([]);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error('Search error:', error);
        toast('error', { message: 'Error while searching files' }, 3000);
        setSearchResults([]);
      } finally {
        setLoading(false);
        setLoadingLabel('');
        setIsSearching(false);
      }
    },
    [handlePathChange]
  );

  const handleSearch = useCallback(
    (newSearch) => {
      setSearch(newSearch);
      if (newSearch.trim() !== '') {
        setIsSearching(true);
        searchFiles(newSearch);
      } else {
        setSearchResults([]);
        setIsSearching(false);
      }
    },
    [searchFiles]
  );

  function downloadFile(data, fileName) {
    const url = URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
  }

  const handleDownload = async (fileName) => {
    try {
      setLoading(true);
      setLoadingLabel('Downloading file...');

      // Construct the full file path
      const fullPath = `${currentPath}/${fileName}`.replace(/^\//, ''); // Remove leading slash if present

      const result = await StorageController.getDocument(fullPath);

      if (result && result.Body) {
        downloadFile(result.Body, fileName);
      } else {
        throw new Error('File content not available');
      }
    } catch (error) {
      console.error('Download error:', error);
      toast('error', { message: 'Error downloading file. Please try again.' }, 3000);
    } finally {
      setLoading(false);
      setLoadingLabel('');
    }
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleUploadFile = async () => {
    const fileKey = FolderNavigationController.getFileKey(
      file ? file.name : '',
      currentPath.split('/').slice(1),
      currentPath.split('/')[0]
    );
    setLoadingLabel('Uploading file...');
    setLoading(true);

    await StorageController.uploadFileToS3(fileKey, file);

    setLoading(false);
    setLoadingLabel('');
    fetchCurrentPathContents(currentPath);

    handleShowModal();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const downloadAllFolderFiles = async () => {
    const folderName = currentPath.split('/').slice(-2).join('_');
    const zip = new JSZip();
    const chunkSize = 150;
    const numberOfFolders = currentContents.folders.length;
    let downloadedFolders = 0;

    setLoadingLabel(`Preparing ${folderName}.zip file...`);

    await chunkPromiseHandler(currentContents.folders, chunkSize, async (folder) => {
      const folderZip = zip.folder(folder);
      const folderContents = await StorageController.listS3Files(`${currentPath}/${folder}`);

      await chunkPromiseHandler(folderContents.files, folderContents.files.length, async (f) => {
        const key = FolderNavigationController.getFileKey(
          f,
          `${currentPath}/${folder}`.split('/').slice(1),
          currentPath.split('/')[0]
        );
        const { Body: blob } = await StorageController.getDocument(key);
        folderZip.file(f, blob);
      });
      downloadedFolders += 1;
      setLoadingLabel(`Downloading ${downloadedFolders} of ${numberOfFolders} loan packages...`);
    });

    const zipContent = await zip.generateAsync({ type: 'blob' });
    setLoadingLabel(`zip file ready, downloading ${folderName}.zip...`);
    downloadFile(zipContent, `${folderName}.zip`);
  };

  const downloadFiles = async () => {
    const folderName = currentPath.split('/').pop();
    setLoadingLabel(`Downloading loan package for ${folderName}...`);

    const filesList = currentContents.files.map((f) => ({
      name: f,
      key: FolderNavigationController.getFileKey(
        f,
        currentPath.split('/').slice(1),
        currentPath.split('/')[0]
      )
    }));

    const filesMetaData = await Promise.all(
      filesList.map(async (f) => {
        const { Body: blob } = await StorageController.getDocument(f.key);
        return { name: f.name, blob };
      })
    );

    const zip = new JSZip();
    filesMetaData.forEach((f) => zip.file(f.name, f.blob));
    const zipContent = await zip.generateAsync({ type: 'blob' });
    downloadFile(zipContent, `${folderName}.zip`);
  };

  const handleDownloadAll = async () => {
    try {
      setLoading(true);

      if (currentContents.files.length > 0) {
        console.time('download files execution time');
        await downloadFiles();
        console.timeEnd('download files execution time');
      }

      if (currentContents.folders.length > 0) {
        console.time('download folder files execution time');
        await downloadAllFolderFiles();
        console.timeEnd('download folder files execution time');
      }

      setLoading(false);
      setLoadingLabel('');
    } catch (error) {
      toast('error', { message: 'Error when trying to download the files' }, 3000);
    } finally {
      setLoading(false);
      setLoadingLabel('');
    }
  };

  const providerValue = useMemo(
    () => ({
      currentPath,
      currentContents,
      handlePathChange,
      handleGoBack,
      handleSearch,
      search,
      searchResults,
      isSearching,
      loading,
      loadingLabel,
      file,
      showModal,
      handleShowModal,
      handleUploadFile,
      handleDownload,
      handleFileChange,
      handleDownloadAll,
      progress,
      setProgress,
      isRoot: currentPath === '/thread',
      previousPath: pathHistory[pathHistory.length - 2] || 'root',
      canShowSearchInput: currentPath.startsWith('/thread')
    }),
    [
      currentPath,
      currentContents,
      handlePathChange,
      handleGoBack,
      handleSearch,
      search,
      searchResults,
      isSearching,
      loading,
      loadingLabel,
      file,
      showModal,
      handleShowModal,
      handleUploadFile,
      handleDownload,
      handleFileChange,
      handleDownloadAll,
      progress,
      pathHistory
    ]
  );

  return <SftpContext.Provider value={providerValue}>{children}</SftpContext.Provider>;
}

SftpProvider.propTypes = {
  children: PropTypes.any.isRequired,
  attributes: PropTypes.object.isRequired
};

const useSftpHook = () => {
  const context = useContext(SftpContext);
  return context;
};

export default useSftpHook;
